import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useEffect } from "react";
import SanitizedHTML from 'react-sanitized-html';
import "uswds/dist/css/uswds.min.css";
import "uswds/dist/js/uswds.js";
import Disclaimer from "../components/disclaimer";
import Footer from "../components/footer";
import Header from "../components/header";
import "../components/header.scss";
import ScrollToTop from "../components/scrollToTop";
import faqStyles from "./faq.module.scss";

export default function Decisions() {
    const decisionData = useStaticQuery(graphql`
        query {
            bad {
                bIAAGDecisions {
                    contentItemId
                    displayText
                    htmlBody {
                      html
                    }
                  }
                bIAAGDecisionsPage {
                    displayText
                    htmlBody {
                      html
                    }
                }
                bIAAGDecisionsSorting {
                    displayText
                    bIAAGDecisionsTopics {
                      contentItemIds
                    }
                }
                bIAAGDecisionsTopics {
                    contentItemId
                    displayText
                    htmlBody {
                      html
                    }
                    bIAAGDecisions {
                      contentItemIds
                    }
                }
            }
        }
    `);
    const bad = decisionData.bad.bIAAGDecisions;
    // JSON.parse(intl.messages.topics)
    const topics = decisionData.bad.bIAAGDecisionsSorting[0].bIAAGDecisionsTopics.contentItemIds.map(id =>  decisionData.bad.bIAAGDecisionsTopics.find(topic => topic.contentItemId === id));

    const allowedHtml = {
        allowedTags: [
        "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
        "h5", "h6", "main", "nav", "section", "blockquote", "dd", "div", "iframe", "img",
        "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p",
        "ul", "a", "abbr", "b", "br","em", "i", "mark", "small", "span", "strong", "time", 
        "caption", "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr"
        ],
        allowedAttributes: {
          'a': [ 'href', 'name', 'target', 'rel', 'class', 'style', 'scroll', 'hidden' ],
          'img': [ 'src' ],
          'iframe': ['src', 'allow']
        },
        allowedIframeHostnames: ['www.youtube.com']
      }

    useEffect(() => {
        document.querySelectorAll('a').forEach(
            link => link.hasAttribute('scroll')
                ? link.addEventListener('click', () => {
                    scrollTo(`#${link.getAttribute('scroll')}`);
                    document.querySelector(`#answer-${link.getAttribute('scroll').split('question-')[1]}`).removeAttribute('hidden');
                    document.querySelector(`[aria-controls="${`answer-${link.getAttribute('scroll').split('question-')[1]}`}"]`).setAttribute('aria-expanded', 'true');
                })
                : ''
        )
        document.querySelectorAll('a').forEach(
            link => {
                if (link.getAttribute('href') && link.getAttribute('href').includes('http')) {
                    link.setAttribute('target', '_blank');
                    link.setAttribute('rel', 'noopener noreferrer')
                }
            }
        );
    });

    function formatHtml(html) {
        if (html !== null) {
            let formattedHtml = html
                .replace(/href="\/Contents\/ContentItems\//g, 'class="text-primary" style="cursor:pointer; text-decoration: underline;" scroll="question-')
                .replace(/\/media\/decisions(.*?)/g, '')
            return formattedHtml
        } else {
            return;
        }
    }

    return (
        <div>
            <Header />
            <main id="main-content">
                <div className="grid-container">
                    <section className={faqStyles.card}>
                        <h1 className="font-serif-lg tablet:font-serif-2xl margin-0 margin-bottom-3 text-center">{decisionData.bad.bIAAGDecisionsPage[0].displayText}</h1>
                        <div className="grid-row display-block">
                            <div className={faqStyles.toc}>
                                <h2 className="margin-bottom-1">TOPICS</h2>
                                <ul className="add-list-reset">
                                    {
                                        topics.map(topic => {
                                            return (
                                                <li className="margin-bottom-1" key={topic.displayText.replace(/\s+/g, '-').toLowerCase()}>
                                                    <a tabIndex="0" className={faqStyles.topic} onClick={() => scrollTo(`#${topic.displayText.replace(/\s+/g, '-').toLowerCase()}`)}>{topic.displayText}</a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="faq margin-x-auto maxw-tablet-lg">
                                {
                                    topics.map(topic => {
                                        return (
                                            <div className="padding-top-2 padding-bottom-4" id={`${topic.displayText.replace(/\s+/g, '-').toLowerCase()}`} key={topic.displayText.replace(/\s+/g, '-').toLowerCase()}>
                                                <h2 className="margin-top-0">{topic.displayText}</h2>
                                                <SanitizedHTML 
                                                    allowedAttributes={allowedHtml.allowedAttributes} 
                                                    allowedTags={allowedHtml.allowedTags} 
                                                    allowedIframeHostnames={allowedHtml.allowedIframeHostnames} 
                                                    html={formatHtml(topic.htmlBody.html)}
                                                />
                                                {
                                                    topic.bIAAGDecisions.contentItemIds.map(resourceId => bad.filter(resource => resource.contentItemId === resourceId)[0]).map((resource, index) => {
                                                        return (
                                                            <div className="usa-accordion" aria-multiselectable="true" id={`question-${resource.contentItemId}`} key={resource.displayText.replace(/\s+/g, '-').toLowerCase()}>

                                                                <button className="usa-accordion__button" aria-expanded="false" aria-controls={`answer-${resource.contentItemId}`}>
                                                                    <h3 className="usa-accordion__heading">{resource.displayText}</h3>
                                                                </button>

                                                                <div id={`answer-${resource.contentItemId}`} className="usa-accordion__content usa-prose" hidden>
                                                                    {<SanitizedHTML 
                                                                        allowedAttributes={allowedHtml.allowedAttributes} 
                                                                        allowedTags={allowedHtml.allowedTags} 
                                                                        allowedIframeHostnames={allowedHtml.allowedIframeHostnames} 
                                                                        html={formatHtml(resource.htmlBody.html)}
                                                                    />}
                                                                    {/* {resource.htmlBody.html !== null
                                                                        ? [...resource.htmlBody.html.matchAll(/(?:\[image\]decisions)(.*?)(?:\[\/image\])/g)]?.map(image => {
                                                                            return (<img src={`${image[1]}`} alt={`${image[1]}`} key={`${image[1]}`} />)
                                                                        })
                                                                        : resource.htmlBody.html
                                                                    } */}
                                                                </div>
                                                            </div> 
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="margin-3">
                                <div className="margin-x-auto maxw-tablet-lg text-bold">
                                    {<SanitizedHTML 
                                        allowedAttributes={allowedHtml.allowedAttributes} 
                                        allowedTags={allowedHtml.allowedTags} 
                                        allowedIframeHostnames={allowedHtml.allowedIframeHostnames} 
                                        html={formatHtml(decisionData.bad.bIAAGDecisionsPage[0].htmlBody.html)}
                                    />}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <SanitizedHTML allowedAttributes={{'a': ['href', 'scroll', 'class', 'style'], 'img': ['src', 'alt'] }} html={'<a href="/hello"> hello</a>'}/> */}
                </div>
            </main>
            <Footer />
            <Disclaimer />
            <ScrollToTop />
        </div>
    )
}


